export const initialAbility = [
  {
    action: "read",
    subject: "Auth",
  },
  {
    action: "all",
    subject: "admin",
  },
  {
    action: "all",
    subject: "sales_department",
  },
  {
    action: "all",
    subject: "operator",
  },
  {
    action: "all",
    subject: "operation_managment",
  },
  {
    action: "all",
    subject: "storehead",
  },
  {
    action: "all",
    subject: "storekeeper",
  },
  {
    action: "all",
    subject: "input",
  },
  {
    action: "all",
    subject: "delivery",
  },
];

export const _ = undefined;
