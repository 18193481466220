import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
var defaultLanguage = sessionStorage.getItem("DefaultLanguage");

if (defaultLanguage == null) {
  var defaultLang = "en";
} else {
  var defaultLang = defaultLanguage;
}
function loadLocaleMessages() {
  //var path = sessionStorage.getItem("path");
  const locales = require.context(
    "/var/www/vhosts/azul-fish.com/api.azul-fish.com/app/parameters/lang/",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
export default new VueI18n({
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages: loadLocaleMessages(),
});
