export default [
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/login-v1",
    name: "auth-login-v1",
    component: () => import("@/views/pages/authentication/Login-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/login-v2",
    name: "auth-login-v2",
    component: () => import("@/views/pages/authentication/Login-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/pages/authentication/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/register-v1",
    name: "auth-register-v1",
    component: () => import("@/views/pages/authentication/Register-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/register-v2",
    name: "auth-register-v2",
    component: () => import("@/views/pages/authentication/Register-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/forgot-password-v1",
    name: "auth-forgot-password-v1",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/forgot-password-v2",
    name: "auth-forgot-password-v2",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v2.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/authentication/reset-password-v1",
    name: "auth-reset-password-v1",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/reset-password-v2",
    name: "auth-reset-password-v2",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/account-setting",
    name: "pages-account-setting",
    component: () => import("@/views/pages/account-setting/AccountSetting.vue"),
    meta: {
      pageTitle: "Account Settings",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Account Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/profile",
    name: "pages-profile",
    component: () => import("@/views/pages/profile/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
    },
  },

  {
    path: "/pages/faq",
    name: "pages-faq",
    component: () => import("@/views/pages/faq/Faq.vue"),
    meta: {
      pageTitle: "FAQ",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "FAQ",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/knowledge-base",
    name: "pages-knowledge-base",
    component: () => import("@/views/pages/Knowledge-base/KnowledgeBase.vue"),
    meta: {
      pageTitle: "Knowledge Base",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Knowledge Base",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/knowledge-base/:category",
    name: "pages-knowledge-base-category",
    component: () =>
      import("@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue"),
    meta: {
      pageTitle: "Category",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Knowledge Base",
          to: "/pages/Knowledge-base",
        },
        {
          text: "Category",
          active: true,
        },
      ],
      navActiveLink: "pages-knowledge-base",
    },
  },
  {
    path: "/pages/knowledge-base/:category/:slug",
    name: "pages-knowledge-base-question",
    component: () =>
      import("@/views/pages/Knowledge-base/KnowledgeBaseCategoryQuestion.vue"),
    meta: {
      pageTitle: "Question",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Knowledge Base",
          to: "/pages/Knowledge-base",
        },
        {
          text: "Category",
          to: "/pages/Knowledge-base/category",
        },
        {
          text: "Question",
          active: true,
        },
      ],
      navActiveLink: "pages-knowledge-base",
    },
  },
  {
    path: "/pages/pricing",
    name: "pages-pricing",
    component: () => import("@/views/pages/pricing/Pricing.vue"),
  },
  {
    path: "/pages/blog/list",
    name: "pages-blog-list",
    component: () => import("@/views/pages/blog/BlogList.vue"),
    meta: {
      pageTitle: "Blog List",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Blog",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/blog/:id",
    name: "pages-blog-detail",
    component: () => import("@/views/pages/blog/BlogDetail.vue"),
    meta: {
      pageTitle: "Blog Detail",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Blog",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/blog/edit/:id",
    name: "pages-blog-edit",
    component: () => import("@/views/pages/blog/BlogEdit.vue"),
    meta: {
      pageTitle: "Blog Edit",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Blog",
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
  {
    path: "/permissions",
    name: "permissions",
    component: () =>
      import("@/views/pages/user/permissions/PermissionList.vue"),
    meta: {
      pageTitle: "Permissions",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Managing Users",
        },
        {
          text: "Permissions",
        },
      ],
    },
  },
  {
    path: "/roles",
    name: "roles",
    component: () => import("@/views/pages/user/roles/RoleList.vue"),
    meta: {
      pageTitle: "Roles",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Managing Users",
        },

        {
          text: "Roles",
        },
      ],
    },
  },
  {
    path: "/users",
    name: "users",

    component: () => import("@/views/pages/user/users/UserList.vue"),
    meta: {
      pageTitle: "Users",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Managing Users",
        },

        {
          text: "Users",
        },
      ],
    },
  },
  {
    path: "/settings/categories",
    name: "categories",
    component: () =>
      import("@/views/pages/settings/categories/CategorieList.vue"),
    meta: {
      pageTitle: "Categories",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Categories",
        },
      ],
    },
  },
  {
    path: "/settings/subcategories",
    name: "subcategories",
    component: () =>
      import("@/views/pages/settings/subcategories/SubCategorieList.vue"),
    meta: {
      pageTitle: "Sub Categories",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Sub Categories",
        },
      ],
    },
  },
  {
    path: "/settings/brands",
    name: "brands",
    component: () => import("@/views/pages/settings/brands/BrandList.vue"),
    meta: {
      pageTitle: "Brands",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Brands",
        },
      ],
    },
  },
  {
    path: "/settings/unitites",
    name: "unitites",
    component: () => import("@/views/pages/settings/unitites/UnitList.vue"),
    meta: {
      pageTitle: "Unitites",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Unitites",
        },
      ],
    },
  },
  {
    path: "/settings/vats",
    name: "vats",
    component: () => import("@/views/pages/settings/vats/VatList.vue"),
    meta: {
      pageTitle: "VATs",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "VATs",
        },
      ],
    },
  },
  {
    path: "/settings/glazaurage",
    name: "glazaurage",
    component: () =>
      import("@/views/pages/settings/glazaurage/GlazaurageList.vue"),
    meta: {
      pageTitle: "Glazaurage",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Glazaurage",
        },
      ],
    },
  },
  {
    path: "/settings/packaging",
    name: "packaging",
    component: () =>
      import("@/views/pages/settings/packaging/PackagingList.vue"),
    meta: {
      pageTitle: "Packaging",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Packaging",
        },
      ],
    },
  },
  {
    path: "/settings/packing",
    name: "packing",
    component: () => import("@/views/pages/settings/packing/PackingList.vue"),
    meta: {
      pageTitle: "Packing",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Packing",
        },
      ],
    },
  },
  {
    path: "/settings/preparations",
    name: "preparations",
    component: () =>
      import("@/views/pages/settings/preparation/PreparationList.vue"),
    meta: {
      pageTitle: "Preparations",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Preparations",
        },
      ],
    },
  },
  {
    path: "/settings/sellingtypes",
    name: "sellingtypes",
    component: () =>
      import("@/views/pages/settings/sellingtype/SellingTypeList.vue"),
    meta: {
      pageTitle: "Selling Types",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Selling Types",
        },
      ],
    },
  },
  {
    path: "/settings/tranches",
    name: "tranches",
    component: () => import("@/views/pages/settings/tranche/TrancheList.vue"),
    meta: {
      pageTitle: "Tranches",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Tranches",
        },
      ],
    },
  },
  {
    path: "/settings/productname",
    name: "productname",
    component: () =>
      import("@/views/pages/settings/productname/ProductNameList.vue"),
    meta: {
      pageTitle: "Product Names",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Product Names",
        },
      ],
    },
  },
  {
    path: "/settings/designation",
    name: "designation",
    component: () =>
      import("@/views/pages/settings/designation/DesignationList.vue"),
    meta: {
      pageTitle: "Designation",
      resource: "sales_department",
      action: "all",
      breadcrumb: [
        {
          text: "Sub profile",
        },
      ],
    },
  },
  {
    path: "/settings/designationprice/:id",
    name: "designationprice",
    component: () =>
      import("@/views/pages/settings/designation/DesignationPriceList.vue"),
    meta: {
      pageTitle: "Designation price",
      resource: "sales_department",
      action: "all",
      breadcrumb: [
        {
          text: "Sub profile",
          to: {
            name: "designation",
          },
        },
        {
          text: "Designation price",
        },
      ],
    },
  },
  /*{
    path: "/notification",
    name: "ViewAllNotifications",
    component: () =>
      import(
        "@/@core/layouts/components/app-navbar/components/Notifications.vue"
      ),
    meta: {
      pageTitle: "Notification",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Notification",
        },
        {
          text: "Read All Notification",
        },
      ],
    },
  },*/
  {
    path: "/settings/languages/:code",
    name: "translateWord",
    component: () => import("@/views/pages/settings/language/wordList.vue"),
    meta: {
      pageTitle: "Global Settings",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Language",
        },
        {
          text: "Translate",
        },
      ],
    },
  },
  {
    path: "/settings/consultedesignationprice/:id",
    name: "consultedesignationprice",
    component: () =>
      import(
        "@/views/pages/settings/designation/ConsulteDesignationPriceList.vue"
      ),
    meta: {
      pageTitle: "Designation price",
      resource: "sales_department",
      action: "all",
      breadcrumb: [
        {
          text: "Sub profile",
          to: {
            name: "designation",
          },
        },
        {
          text: "Consulte Designation Price",
        },
      ],
    },
  },
  {
    path: "/settings/productcombination",
    name: "productcombination",
    component: () =>
      import(
        "@/views/pages/settings/productcombination/ProductCombinationList.vue"
      ),
    meta: {
      pageTitle: "Product Combination",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Products",
        },
        {
          text: "Product Combination",
        },
      ],
    },
  },
  {
    path: "/settings/clients",
    name: "clients",
    component: () => import("@/views/pages/settings/clients/ClientList.vue"),
    meta: {
      pageTitle: "Clients",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Clients Settings",
        },
      ],
    },
  },
  {
    path: "/settings/annexes",
    name: "clientsannexes",
    component: () =>
      import("@/views/pages/settings/clientannexe/ClientAnnexeList.vue"),
    meta: {
      pageTitle: "Clients Annexes",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Clients Settings",
        },
        {
          text: "Clients Annexes",
        },
      ],
    },
  },
  {
    path: "/settings/contacts",
    name: "clientscontants",
    component: () =>
      import("@/views/pages/settings/clientcontact/ClientContactList.vue"),
    meta: {
      pageTitle: "Clients Contacts",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Clients Settings",
        },
        {
          text: "Clients Contacts",
        },
      ],
    },
  },
  {
    path: "/settings/profiles",
    name: "clientprofiles",
    component: () => import("@/views/pages/settings/profile/ProfileList.vue"),
    meta: {
      pageTitle: "Profiles",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Clients Settings",
        },
        {
          text: "Profiles",
        },
      ],
    },
  },
  {
    path: "/settings/subprofiles",
    name: "clientsubprofiles",
    component: () =>
      import("@/views/pages/settings/subprofile/SubProfileList.vue"),
    meta: {
      pageTitle: "Sub Profiles",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Clients Settings",
        },
        {
          text: "Sub Profiles",
        },
      ],
    },
  },
  {
    path: "/settings/lot",
    name: "stocklots",
    component: () => import("@/views/pages/settings/lot/LotList.vue"),
    meta: {
      pageTitle: "Lot",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Stock Settings",
        },
        {
          text: "Lot",
        },
      ],
    },
  },
  {
    path: "/settings/addquotation",
    name: "addquotation",
    component: () =>
      import("@/views/pages/settings/quotation/AddQuotationList.vue"),
    meta: {
      pageTitle: "Add Quotation",
      resource: "sales_department",
      action: "all",
      breadcrumb: [
        {
          text: "Quotation",
        },
        {
          text: "Add Quotation",
        },
      ],
    },
  },
  {
    path: "/settings/quotations",
    name: "quotations",
    component: () =>
      import("@/views/pages/settings/quotation/QuotationList.vue"),
    meta: {
      pageTitle: "Quotation List",
      resource: "sales_department",
      action: "all",
      breadcrumb: [
        {
          text: "Quotation",
        },
        {
          text: "Quotation List",
        },
      ],
    },
  },

  {
    path: "/settings/physicaldepot",
    name: "physicaldepot",
    component: () =>
      import("@/views/pages/settings/physicaldepot/PhysicalDepotList.vue"),
    meta: {
      pageTitle: "Physical Depot",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Stock Settings",
        },
        {
          text: "Physical Depot",
        },
      ],
    },
  },
  {
    path: "/settings/depot",
    name: "depot",
    component: () => import("@/views/pages/settings/depot/DepotList.vue"),
    meta: {
      pageTitle: "Depot",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Stock Settings",
        },
        {
          text: "Depot",
        },
      ],
    },
  },
  {
    path: "/settings/cities",
    name: "cities",
    component: () => import("@/views/pages/settings/city/CityList.vue"),
    meta: {
      pageTitle: "Cities",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Delivery Settings",
        },
        {
          text: "Cities",
        },
      ],
    },
  },
  {
    path: "/settings/docks",
    name: "docks",
    component: () => import("@/views/pages/settings/dock/DockList.vue"),
    meta: {
      pageTitle: "Docks",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Delivery Settings",
        },
        {
          text: "Docks",
        },
      ],
    },
  },
  {
    path: "/settings/vehicles",
    name: "vehicles",
    component: () => import("@/views/pages/settings/vehicle/VehicleList.vue"),
    meta: {
      pageTitle: "Vehicles",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Delivery Settings",
        },
        {
          text: "Vehicles",
        },
      ],
    },
  },
  {
    path: "/settings/cityareas",
    name: "cityareas",
    component: () => import("@/views/pages/settings/cityarea/CityAreaList.vue"),
    meta: {
      pageTitle: "City Areas",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Delivery Settings",
        },
        {
          text: "City Areas",
        },
      ],
    },
  },
  {
    path: "/settings/shippingtypes",
    name: "shippingtypes",
    component: () =>
      import("@/views/pages/settings/shippingtype/ShippingTypeList.vue"),
    meta: {
      pageTitle: "Shipping Types",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Delivery Settings",
        },
        {
          text: "Shipping Types",
        },
      ],
    },
  },
  {
    path: "/settings/ordersources",
    name: "ordersources",
    component: () =>
      import("@/views/pages/settings/ordersource/OrderSourceList.vue"),
    meta: {
      pageTitle: "Order Sources",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Order Sources",
        },
      ],
    },
  },
  {
    path: "/settings/orderstates",
    name: "orderstates",
    component: () =>
      import("@/views/pages/settings/orderstatus/OrderStatusList.vue"),
    meta: {
      pageTitle: "Order Status",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Order Status",
        },
      ],
    },
  },
  {
    path: "/settings/emergencydegrees",
    name: "emergencydegrees",
    component: () =>
      import("@/views/pages/settings/emergencydegree/EmergencyDegreeList.vue"),
    meta: {
      pageTitle: "Emergency Degrees",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Emergency Degrees",
        },
      ],
    },
  },
  {
    path: "/settings/wordings",
    name: "wordings",
    component: () => import("@/views/pages/settings/wording/WordingList.vue"),
    meta: {
      pageTitle: "Wording",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Global Settings",
        },
        {
          text: "Wording",
        },
      ],
    },
  },
  {
    path: "/settings/languages",
    name: "languages",
    component: () => import("@/views/pages/settings/language/LanguageList.vue"),
    meta: {
      pageTitle: "Language",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Global Settings",
        },
        {
          text: "Language",
        },
      ],
    },
  },
  {
    path: "/settings/path",
    name: "path",
    component: () => import("@/views/pages/settings/path/PathList.vue"),
    meta: {
      pageTitle: "Path",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Global Settings",
        },
        {
          text: "Path",
        },
      ],
    },
  },
  {
    path: "/orders/managerorderstracking",
    name: "managerorderstracking",
    component: () =>
      import("@/views/pages/orders/manager-order-tracking/OrderTracking.vue"),
    meta: {
      pageTitle: "Order Tracking",
      resource: "operation_managment",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Order Tracking",
        },
      ],
    },
  },
  {
    path: "/orders/storeheadorderstracking",
    name: "storeheadorderstracking",
    component: () =>
      import("@/views/pages/orders/storehead-order-tracking/OrderTracking.vue"),
    meta: {
      pageTitle: "Order Tracking",
      resource: "storehead",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Order Tracking",
        },
      ],
    },
  },
  {
    path: "/orders/storekeeperorderstracking",
    name: "storekeeperorderstracking",
    component: () =>
      import(
        "@/views/pages/orders/storekeeper-order-tracking/OrderTracking.vue"
      ),
    meta: {
      pageTitle: "Order Tracking",
      resource: "storekeeper",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Order Tracking",
        },
      ],
    },
  },
  {
    path: "/orders/deliveryorderstracking",
    name: "deliveryorderstracking",
    component: () =>
      import("@/views/pages/orders/delivery-order-tracking/OrderTracking.vue"),
    meta: {
      pageTitle: "Order Tracking",
      resource: "delivery",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Order Tracking",
        },
      ],
    },
  },
  {
    path: "/orders/OrderAffectation",
    name: "OrderAffectation",
    component: () =>
      import(
        "@/views/pages/orders/delivery-order-tracking/OrderAffectation.vue"
      ),
    meta: {
      pageTitle: "Orders",
      resource: "delivery",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Orders",
        },
      ],
    },
  },
  {
    path: "/orders/deliveryreturntracking",
    name: "deliveryreturntracking",
    component: () =>
      import("@/views/pages/orders/delivery-order-tracking/ReturnOrder.vue"),
    meta: {
      pageTitle: "Return Tracking",
      resource: "delivery",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Return Tracking",
        },
      ],
    },
  },
  {
    path: "/orders/sellerordertracking",
    name: "sellerordertracking",
    component: () =>
      import("@/views/pages/orders/seller-order-tracking/TrackingOrder.vue"),
    meta: {
      pageTitle: "Order Tracking",
      resource: "seller",
      action: "all",
      breadcrumb: [
        {
          text: "Order Settings",
        },
        {
          text: "Order Tracking",
        },
      ],
    },
  },
  {
    path: "/chargetracking",
    name: "chargetracking",
    component: () =>
      import("@/views/pages/orders/manager-order-tracking/ChargeTracking.vue"),
    meta: {
      pageTitle: "Charge Tracking",
      resource: "operation_managment",
      action: "all",
      breadcrumb: [
        {
          text: "Charge Tracking",
        },
      ],
    },
  },
  {
    path: "/orderlinetracking",
    name: "orderlinetracking",
    component: () =>
      import(
        "@/views/pages/orders/manager-order-tracking/OrderLineTracking.vue"
      ),
    meta: {
      pageTitle: "Order Line Tracking",
      resource: "operation_managment",
      action: "all",
      breadcrumb: [
        {
          text: "Order Line Tracking",
        },
      ],
    },
  },
  {
    path: "/orders/addorder",
    name: "addorder",
    component: () => import("@/views/pages/orders/commercial/AddOrder.vue"),
    meta: {
      pageTitle: "Add Order",
      resource: "operator",
      action: "all",
      breadcrumb: [
        {
          text: "Orders",
        },
        {
          text: "Add order",
        },
      ],
    },
  },
  {
    path: "/orders/orderList",
    name: "orderList",
    component: () =>
      import("@/views/pages/orders/order-list/OrderTracking.vue"),
    meta: {
      pageTitle: "Order List",
      resource: "operator",
      action: "all",
      breadcrumb: [
        {
          text: "Orders",
        },
        {
          text: "Order List",
        },
      ],
    },
  },
  {
    path: "/orders/sellerorder",
    name: "sellerorder",
    component: () => import("@/views/pages/orders/seller-order-tracking/AddOrder.vue"),
    meta: {
      pageTitle: "Add Order",
      resource: "seller",
      action: "all",
      breadcrumb: [
        {
          text: "Orders",
        },
        {
          text: "Add order",
        },
      ],
    },
  },
  {
    path: "/deliverynote",
    name: "deliverynote",
    component: () =>
      import("@/views/pages/orders/delivery-note/AddDeliveryNote.vue"),
    meta: {
      pageTitle: "Delivery Note",
      resource: "input",
      action: "all",
      breadcrumb: [
        {
          text: "Delivery Note",
        },
      ],
    },
  },
  {
    path: "/adddeliverynote/:id",
    name: "adddeliverynote",
    component: () =>
      import("@/views/pages/orders/delivery-note/AddDeliveryNote.vue"),
    meta: {
      pageTitle: "Delivery Note",
      resource: "input",
      action: "all",
      breadcrumb: [
        {
          text: "Delivery Note",
          to: {
            name: "deliverynote",
          },
        },
        {
          text: "Add delivery note",
        },
      ],
    },
  },
  {
    path: "/recovery",
    name: "recovery",
    component: () => import("@/views/pages/orders/recovery/RecoveryList.vue"),
    meta: {
      pageTitle: "Recovery List",
      resource: "recoverer",
      action: "all",
      breadcrumb: [
        {
          text: "Recovery List",
        },
      ],
    },
  },
  {
    path: "/validaterecovery",
    name: "validaterecovery",
    component: () =>
      import("@/views/pages/orders/recovery/ValidateRecoveryList.vue"),
    meta: {
      pageTitle: "Recovery List",
      resource: "recoverer",
      action: "all",
      breadcrumb: [
        {
          text: "Recovery List",
        },
      ],
    },
  },
  {
    path: "/visits",
    name: "visits",
    component: () => import("@/views/pages/orders/recovery/VisitList.vue"),
    meta: {
      pageTitle: "Visit List",
      resource: "recoverer",
      action: "all",
      breadcrumb: [
        {
          text: "Visit List",
        },
      ],
    },
  },
  {
    path: "/validaterecoverydetail",
    name: "validaterecoverydetail",
    component: () =>
      import("@/views/pages/orders/recovery/ValidatedRecoveryDetailList.vue"),
    meta: {
      pageTitle: "Recovery List",
      resource: "recoverer",
      action: "all",
      breadcrumb: [
        {
          text: "Recovery List",
          to: {
            name: "validaterecovery",
          },
        },
        {
          text: "Detail",
        },
      ],
    },
  },
  {
    path: "/designationpricehistory",
    name: "designationpricehistory",
    component: () =>
      import(
        "@/views/pages/history/designation-price/DesignationPriceHistory.vue"
      ),
    meta: {
      pageTitle: "Designation Price History",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "History List",
        },
      ],
    },
  },

  {
    path: "/settings/quotations/:id",
    name: "editquotations",
    component: () =>
      import("@/views/pages/settings/quotation/EditQuotation.vue"),
    meta: {
      pageTitle: "Edit Quotation",
      resource: "sales_department",
      action: "all",
      breadcrumb: [
        {
          text: "Quotations",
          to: {
            name: "quotations",
          },
        },
      ],
    },
  },
  {
    path: "/recoveries/situations",
    name: "situations",
    component: () => import("@/views/pages/situation/SituationList.vue"),
    meta: {
      pageTitle: "Situation List",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Situation List",
        },
      ],
    },
  },
  {
    path: "/recoveries/addsituations",
    name: "addsituations",
    component: () => import("@/views/pages/situation/AddSituationList.vue"),
    meta: {
      pageTitle: "Add Situation",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Situation",
        },
      ],
    },
  },
  {
    path: "/recoveries/globalsituations",
    name: "globalsituations",
    component: () => import("@/views/pages/situation/GlobalSituationList.vue"),
    meta: {
      pageTitle: "Global Situation",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Global Situation",
        },
      ],
    },
  },
  {
    path: "/profile",
    name: "user-profile",
    component: () => import("@/views/pages/user/users/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      resource: "admin",
      action: "all",
      breadcrumb: [
        {
          text: "Profile",
        },
      ],
    },
  },
];
