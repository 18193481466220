import Vue from "vue";

// axios
import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.azul-fish.com/api/v1",
  headers: { "Content-Type": "application/json" },
});

function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem("access");
  return accessToken;
}
function getLocalRefreshToken() {
  const refreshToken = window.localStorage.getItem("refresh");
  return refreshToken;
}

function refreshToken() {
  return instance.post("/token/refresh/", {
    refresh: getLocalRefreshToken(),
  });
}

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        const refresh = getLocalRefreshToken();
        if (refresh) {
          try {
            const rs = await refreshToken();
            const { access } = rs.data;
            window.localStorage.setItem("access", access);
            instance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${access}`;
            return instance(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        }
      }
      if (err.response.status === 401 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

Vue.prototype.$http = instance;

export default instance;
